import React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

function Posts() {
  return (
    <div style={{
        display: 'flex',
        
        alignItems: 'center',
        flexWrap: 'wrap',
    }}>
   
    <Card sx={{ maxWidth: 345, marginRight: '10px' }}>
      <CardMedia
        sx={{ height: 140 }}
        image='https://content.fortune.com/wp-content/uploads/2023/01/AP23031602089774.jpg?w=1440&q=75'
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        ChatGPT just created a new tool to catch students trying to cheat using ChatGPT

        </Typography>
        <Typography variant="body2" color="text.secondary">
        The maker of ChatGPT is trying to curb its reputation as a freewheeling cheating machine with a new tool that can help teachers detect if a student or artificial intelligence wrote that homework.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" 
            onClick={() => window.open('https://fortune.com/2023/01/31/chatgpt-open-ai-new-tool-catch-students-cheating/', '_blank')}
        >Learn More</Button>
      </CardActions>
    </Card>
    <Card sx={{ maxWidth: 345, minHeight: '425px' }}>
      <CardMedia
        sx={{ height: 140 }}
        image='https://th.bing.com/th/id/OIP.q-GCIBk0ebDa9UV9W4ILfAHaE7?pid=ImgDet&rs=1'
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
            Colleges across are banning ChatGPT. Here’s why.


        </Typography>
        <Typography variant="body2" color="text.secondary">
        Since ChatGPT debuted in November, the nation's largest school districts have banned the artificial intelligence chatbot, concerned students will use the speedy text generator to cheat or plagiarize. 
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" 
            onClick={() => window.open('https://www.usatoday.com/story/news/education/2023/01/30/chatgpt-going-banned-teachers-sound-alarm-new-ai-tech/11069593002/', '_blank')}
        >Learn More</Button>
      </CardActions>
    </Card>
    </div>
    );
}

export default Posts;



