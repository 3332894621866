import React from 'react';
import Header from './Header';
import Grid from '@mui/material/Unstable_Grid2'; 
import TextField from '@mui/material/TextField';
import { Container, IconButton } from '@mui/material';
import { lightTheme, darkTheme } from './Colors.tsx';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import { generatePrompts } from './util/openai';
import { Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Posts from './Posts';
function App() {
  const [theme, setTheme] = React.useState('light');
  const [prompt, setPrompt] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: theme === 'light' ? lightTheme.text : darkTheme.text,
    },
    '& label': {
      color: theme === 'light' ? lightTheme.text : darkTheme.text,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme === 'light' ? lightTheme.text : darkTheme.text,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme === 'light' ? lightTheme.text : darkTheme.text,
      },
      '&:hover fieldset': {
        borderColor: theme === 'light' ? lightTheme.text : darkTheme.text,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme === 'light' ? lightTheme.text : darkTheme.text,
      },
    },
    '& .MuiInputBase-input': {
      color: theme === 'light' ? lightTheme.text : darkTheme.text,
    },
  
  }
    
  );


  const getPrompt = async () => {
    setLoading(true);
    const prompt = document.getElementById('text-input').value;
    if (prompt === '') {
      alert('Please insert text to rewrite');
      return;
    }
    const prompts = await generatePrompts(prompt);
    console.log(prompts);
    setPrompt(prompts.data.choices[0].text);
    setLoading(false);
  }

  const handleHelpClick = () => {
    window.open('https://docs.cohere.ai/docs/prompt-engineering', '_blank');
  }

  const handleThemeChange = () => {
    const body = document.querySelector('body');
    if (theme === 'light') {
      setTheme('dark');
      body.style.backgroundColor = darkTheme.background;
    } else {
      setTheme('light');
      body.style.backgroundColor = lightTheme.background;
    }
  }

  

  return (
    <div style={{
      backgroundColor: theme === 'light' ? lightTheme.background : darkTheme.background,
    }} >
    <Header theme={theme}/>
    <Container>
      <Grid item xs={12}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
            <h1 style={{
              color: theme === 'light' ? lightTheme.text : darkTheme.text,
              fontWeight: '400',
            }}>Essay Rewriter</h1>
          
            <IconButton aria-label="delete">
                {theme === 'light' ? <LightModeOutlinedIcon onClick={() => handleThemeChange()} sx={{
                  color: theme === 'light' ? lightTheme.text : darkTheme.text,
                }}/> : <DarkModeOutlinedIcon onClick={() => handleThemeChange()} sx={{
                  color: theme === 'light' ? lightTheme.text : darkTheme.text,
                }}/>}
            </IconButton>
           
         

        </div>
        <div>
          <p style={{
              color: theme === 'light' ? lightTheme.text : darkTheme.text,
              fontWeight: '400',
            }}>
            Make your essay better or sound human by using our essay rewriter. Our essay rewriter is a free tool that will rewrite any given text into readable text along. If you want to use this tool, all you have to do is copy and paste your content into the text box, and our rewriter will do the rest.
          </p>
        </div>
          
          <CssTextField label="Insert Text Here" id="text-input" fullWidth multiline rows={12} variant="outlined" />
          <Button variant="contained" onClick={() => getPrompt()} sx={{marginTop: "10px", marginRight: "2px"}}>{loading ? <CircularProgress color="inherit" size={
            20
          }/> : <>Rewrite</>}</Button> 


        <h2 style={{
              color: theme === 'light' ? lightTheme.text : darkTheme.text,
              fontWeight: '400',
            }}>Essay Output</h2>
        <Divider sx={{marginTop: "10px", marginBottom: "10px"}}/>
       
        {
          prompt !== '' ? <p style={{
            color: theme === 'light' ? lightTheme.text : darkTheme.text,
            fontWeight: '400',
          }}>{prompt}</p> : <> 
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
         
        
        </>
        }
        <Divider sx={{marginTop: "10px", marginBottom: "10px", color: theme === 'light' ? lightTheme.text : darkTheme.text}}/>
      </Grid>
      <h2 style={{
              color: theme === 'light' ? lightTheme.text : darkTheme.text,
              fontWeight: '400',
            }}>How does it work? - Prompt Engineering<IconButton aria-label="delete" onClick={() => handleHelpClick()}>
                <HelpOutlineIcon color={theme === 'light' ? lightTheme.text : 'primary'}/> </IconButton></h2>
      <p style={{
            color: theme === 'light' ? lightTheme.text : darkTheme.text,
            fontWeight: '300',
          }}>
        Prompt engineering is a technique used to fine-tune language models like GPT-3 by providing specific examples or templates to follow. This helps students better understand how the language model works and how to generate more accurate and relevant responses.
      For example, if a student is using GPT-3 to generate answers to questions about a specific topic, they can provide a prompt that includes information about that topic. The model will then use this information to generate more informed and relevant answers.
      Additionally, prompt engineering can be used to steer the model towards certain outputs or to encourage the model to use specific language patterns. This can be helpful for students who are learning about language generation and want to see how the model responds to different inputs.
      </p>
      <Posts />
     

    </Container>

    <Container style={{
     padding: '20px',
    }}>
      <Grid item xs={12}>
      <p style={{
            color: theme === 'light' ? lightTheme.text : darkTheme.text,
            fontWeight: '400',
            
          }}> © 2023 Essay Rewriter. Ovicore Technologies</p>
      </Grid>
    </Container>
    
    </div>
  );
}


export default App;
