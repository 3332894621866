// define color themes
export const lightTheme = {
    body: '#ffffff',
    text: '#2b313e',
    toggleBorder: '#FFF',
    background: '#ffffff',
}
export const darkTheme = {
    body: '#2b313e',
    text: '#ffffff',
    toggleBorder: '#6B8096',
    background: '#2b313e',
}
