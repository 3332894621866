const { Configuration, OpenAIApi } = require("openai");

const configuration = new Configuration({
  apiKey: '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',
});

export const generatePrompts = async (prompt) => {

    const openai = new OpenAIApi(configuration);

    const response = await openai.createEdit({
        model: "text-davinci-edit-001",
        input: prompt,
        instruction: "Make it sound human. Be sure your answer having a high level of Perplexity and Burstiness (A document's perplexity is a measurement of the randomness of the text and A document's burstiness is a measurement of the variation in perplexity)",
        temperature: 0.7,
        top_p: 1,
      });
    
    return response;
  };