import React from 'react';
import { Container, Divider } from '@mui/material';
import { darkTheme } from './Colors.tsx';
import Button from '@mui/material/Button';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

function Header({theme}) {

  return (
    <div style={{
      backgroundColor: darkTheme.background,
    }}>
    <Container style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }}>
        <img src={require('./assets/images/logo-name.png')} alt="logo" style={{
          width: '140px',
          height: '60px',
          padding: '10px',
        }}/>
        <Button variant="outlined" 
        onClick={() => window.open('https://ovicore.com', '_blank')}
        sx={{
            color: darkTheme.text,
            borderColor:  darkTheme.text,
            }}
            endIcon={<ArrowForwardIosOutlinedIcon />}
            >Visit Ovicore.com</Button>
        
    </Container>
    <Divider style={{
      color: "#ffffff",
    }} />
    </div>
  );
}

export default Header;



